import React from 'react'
import './Wyksztalcenie.scss'

const Wyksztalcenie = () => {
    return (
        <div className='wyksztalcenie' id='omnie'>
            <h2>Wykształcenie i uprawnienia</h2>
            <div className='desc-bg'>
                <div className='desc'>
                    <p>• Uniwersytet Szczeciński, Podyplomowe Studia Zarządzania
                    <br/>Przedsiębiorstwem, 1996 r.
                    <br/>• Akademia Rolniczo-Techniczna im. M. Oczapowskiego w Olsztynie, Wydział
                    <br/>Geodezji i Gospodarki Przestrzennej, Podyplomowe Studium Wyceny 
                    <br/>Nieruchomości, 1998</p>
                    <hr />
                    <h3>
                        <p>
                        Od 2010 roku współpracuję z firmą „Biuro Budowlane i Nieruchomości inż. bud. Michał Uniejewski”, gdzie zajmuję się wycenami nieruchomości, głównie w kontekście rozliczeń pomiędzy stronami według ich udziałów.
                        </p>
                    </h3>
                    <hr />
                    <h3>
                        Nr Uprawnień: 5097
                        <br />Data wpisu do rejestru rzeczoznawców majątkowych: 4 października 2010 r.
                    </h3>
                </div>
            </div>
        </div>
    )
}

export default Wyksztalcenie