import React from 'react'
import './Footer.scss'
import LogoWhite from '../Assets/LOGOwhite.avif'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'



const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-blue'>
                <div className='footer-blue-content'>
                <a href='/'><img src={LogoWhite} alt='logo rzeczoznawca majątkowy' /></a>
                    <nav className='footer-menu'>
                        <ul>
                            <li><HashLink to='/#omnie' className='footer-menu-link'>O mnie</HashLink></li>
                            <li><HashLink to='/#kontakt' className='footer-menu-link'>Kontakt</HashLink></li>
                            <li><Link to='/polityka-prywatnosci' className='footer-menu-link'>Polityka prywatności</Link></li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div className='footer-white'>
                <div className='footer-white-content'>
                    <div className='copyright'>
                        <h6>Copyright © 2024 Rzeczoznawca Majątkowy Bogusław Jurgiel | Wszystkie prawa do treści zastrzeżone.</h6>
                    </div>
                    <div className='projekt'>
                        <h6>Projekt i wdrożenie: <a href='https://scharmach.pl/' target='_blank' rel='noreferrer'>Scharmach.pl</a></h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer