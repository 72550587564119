import React from 'react'
import './Hero.scss'
import Sygnet from '../Assets/sygnet.avif'
import Chat from '../Assets/chat.avif'
import { HashLink } from 'react-router-hash-link'


const Hero = () => {
    return (
        <div className='rzeczoznawcy-hero'>
            <div className='hero-left'>
                <div className='left-top'>
                    <img src={Sygnet} alt='' />
                    <h1>Rzetelna wycena,<br/>
                    pewna wartość !</h1>
                </div>
                <div className='left-bottom'>
                <p>Nazwam się Bogusław Jurgiel jestem członkiem Polskiego Stowarzyszenia Wyceny Nieruchomości, oddział Szczecin.
                Posiadam wieloletnie doświadczenie w wycenie nieruchomości zdobyte na przestrzeni lat pracy w zawodzie.</p>
                <h3>Zapraszam do współpracy!</h3>
                <HashLink to='/#kontakt' className='contact-link'><button>kontakt <img src={Chat} alt='' /></button></HashLink>
                </div>

            </div>
            <div className='hero-right'>

            </div>
        </div>
    )
}

export default Hero