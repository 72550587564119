import React, { useState } from 'react';
import './Kontakt.scss';
import LiniaPion from '../Assets/liniapion.avif';
import PhoneIcon from '../Assets/phone-icon.avif';
import MailIcon from '../Assets/mail.avif';
import Mapa from '../Assets/mapa.avif';
import SendMsg from '../Assets/send.avif';

const Kontakt = () => {
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        message: '',
        consent: false
    });

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const fd = new FormData();
        for (const key in formData) {
            fd.append(key, formData[key]);
        }

        fetch('./contact.php', {
            mode: 'no-cors',
            method: 'POST',
            body: fd,
        })
            .then((response) => {
                alert('Formularz został wysłany!');
            })
            .catch((error) => {
                console.error('Wystąpił błąd podczas wysyłania formularza:', error);
            });
    };

    return (
        <div className='kontakt' id='kontakt'>
            <h2>Kontakt</h2>
            <div className='kontakt-grid'>
                <div className='kontakt-left'>
                    <h3>Napisz do mnie</h3>
                    <p className='first-paragraph'>
                        Jeśli potrzebujesz wyceny nieruchomości,
                        <br />
                        skontaktuj się ze mną.
                        <br />
                        Z chęcią rozwieje twoje wszystkie wątpliwości
                        <br />
                        związane z wyceną nieruchomości.
                    </p>
                    <div className='mail-me'>
                        <img src={MailIcon} alt='mail' />
                        <a href='mailto: boguslaw.jurgiel@gmail.com'>
                            <h5>boguslaw.jurgiel@gmail.com</h5>
                        </a>
                    </div>
                    <h4>Wolisz zadzwonić nie ma problemu</h4>
                    <div className='call-me'>
                        <img src={PhoneIcon} alt='telefon' />
                        <a href='tel: +48608040125'>
                            <h5>+ 48 608 040 125</h5>
                        </a>
                    </div>
                    <h4>Adres</h4>
                    <p className='second-paragraph'>
                        ul. Szosa Polska 6A,
                        <br />
                        71-800 Szczecin
                    </p>
                    <img src={Mapa} alt='mapa' className='mapa' />
                </div>
                <div className='kontakt-middle'>
                    <img src={LiniaPion} alt='' />
                </div>
                <div className='kontakt-right'>
                    <form className='contact-form' onSubmit={handleSubmit}>
                        <input
                            type='text'
                            name='name'
                            placeholder='Wpisz imię'
                            required
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                        <input
                            type='text'
                            name='company'
                            placeholder='Nazwa firmy (opcjonalnie)'
                            value={formData.company}
                            onChange={handleInputChange}
                        />
                        <input
                            type='email'
                            name='email'
                            placeholder='Podaj adres e-mail'
                            required
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                        <input
                            type='tel'
                            name='phone'
                            placeholder='Podaj numer telefonu'
                            required
                            value={formData.phone}
                            onChange={handleInputChange}
                        />
                        <textarea
                            name='message'
                            placeholder='Twoja wiadomość'
                            value={formData.message}
                            onChange={handleInputChange}
                        ></textarea>
                        <div className='consent'>
                            <input
                                type='checkbox'
                                id='consent'
                                name='consent'
                                checked={formData.consent}
                                onChange={handleInputChange}
                            />
                            <label htmlFor='consent'>
                                Wyrażam zgodę na otrzymywanie informacji handlowych i marketingowych.
                            </label>
                        </div>
                        <button type='submit' className='submit-btn'>
                            wyślij wiadomość <img src={SendMsg} alt='' />
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Kontakt;
