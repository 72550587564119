import React from 'react'
import './PrivacyPolicy.scss'

const PrivacyPolicy = () => {
  return (
    <div className='polityka-prywatnosci'>
      <h1>Polityka prywatności</h1>
      <p>Drogi Użytkowniku!
        <br />
        <br />
        Dbamy o Twoją prywatność i chcemy, abyś w czasie korzystania z naszych usług czuł się komfortowo. Dlatego też poniżej prezentujemy Ci najważniejsze informacje o zasadach przetwarzania przez nas Twoich danych osobowych oraz plikach cookies, które są wykorzystywane przez nasz Serwis. Informacje te zostały przygotowane z uwzględnieniem RODO, czyli ogólnego rozporządzenia o ochronie danych.
      </p>
      <h2>ADMINISTRATOR DANYCH OSOBOWYCH (“Administrator”)</h2>
      <p>BOGUSŁAW JURGIEL, przedsiębiorca prowadzący działalność gospodarczą pod nazwą RZECZOZNAWCA MAJĄTKOWY BOGUSŁAW JURGIEL
      , wpisany do Centralnej Ewidencji i Informacji o Działalności Gospodarczej prowadzonej przez Ministra Rozwoju i Finansów, NIP 8521333406 , nr REGON 320006957, woj. ZACHODNIOPOMORSKIE, pow. Szczecin, gm. Szczecin, miejsc. Szczecin, SZOSA POLSKA, nr 6A
      </p>
      <h2>DANE OSOBOWE I PRYWATNOŚĆ</h2>
      <p>Jeśli zamierzasz założyć Konto Użytkownika oraz korzystać z naszych usług, zostaniesz poproszony o podanie nam Twoich danych osobowych.
        Twoje dane przetwarzane są przez nas we wskazanych poniżej celach, związanych z funkcjonowaniem Serwisu i świadczeniem usług w nim oferowanych („Usługi”).
      </p>
      <br />
      <p>Cel przetwarzania:
        <br />
        W zależności od tego, na co się zdecydujesz, może to być:
        <br />
        <ul>
          <li>Świadczenie usług oferowanych w Serwisie</li>
          <li>Realizacja Twoich zamówień</li>
          <li>Przesyłanie newslettera</li>
          <li>Dostarczenie zamówionej oferty</li>
        </ul>
        <br />
        Podstawa przetwarzania:
        <br />
        <ul>
          <li>Umowa sprzedaży (art. 6 ust. 1 lit. b RODO)</li>
          <li>Ciążący na nas obowiązek prawny, np. związany z rachunkowością (art. 6 ust. 1 lit. c RODO)</li>
          <li>Twoja zgoda wyrażona w Sklepie (art. 6 ust. 1 lit. a RODO)</li>
          <li>Umowa o świadczenie usług (art. 6 ust. 1 lit. b RODO)</li>
          <li>Nasz prawnie uzasadniony interes, w celu ustalenia, dochodzenia lub obrony roszczeń (art. 6 ust. 1 lit. f RODO)</li>
        </ul>
        <br />
        Podanie danych:
        <br />
        <br />
        Dobrowolne, ale w niektórych przypadkach może być konieczne do zawarcia umowy lub przedstawienia oferty.

        <br />
        <br />
        Skutek niepodania danych:
        <br />
        <br />
        W zależności od celu, w jakim dane są podawane:
        <ul>
          <li>brak możliwości zarejestrowania się w Serwisie</li>
          <li>brak możliwości korzystania z usług Serwisie</li>
          <li>brak możliwości dokonania zakupów w Serwisie</li>
          <li>brak możliwości otrzymywania informacji o promocjach czy ofertach specjalnych, oferowanych w Serwisie</li>
        </ul>
        <br />
        Możliwość cofnięcia zgody:
        <br />
        <br />
        w każdej chwili
        <br />
        <br />
        Przetwarzanie danych do momentu cofnięcia przez Ciebie zgody pozostaje zgodne z prawem.
        <br />
      </p>
      <h2>OKRES PRZETWARZANIA</h2>
      <p>
        Twoje dane będziemy przetwarzać tylko przez okres, w którym będziemy mieć ku temu podstawę prawną, a więc do momentu, w którym:

        przestanie ciążyć na nas obowiązek prawny, zobowiązujący nas do przetwarzania Twoich danych
        ustanie możliwość dochodzenia roszczeń związanych z umową zawartą przez Serwis przez którąkolwiek ze stron
        cofniesz zgodę na przetwarzanie danych, jeśli to ona była jego podstawą


        – w zależności od tego, co ma zastosowanie w danym przypadku i co nastąpi najpóźniej.
      </p>
      <h2>BEZPIECZEŃSTWO DANYCH</h2>
      <p>Przetwarzając Twoje dane osobowe stosujemy środki organizacyjne i techniczne zgodne z właściwymi przepisami prawa.
      </p>

      <h2>TWOJE UPRAWNIENIA</h2>
      <p>
        Przysługuje Ci prawo żądania:
        <ul>
          <li>dostępu do Twoich danych osobowych</li>
          <li>ich sprostowania</li>
          <li>usunięcia</li>
          <li>ograniczenia przetwarzania</li>
        </ul>
        <br />
        <br />
        A także prawo:
        <br />
        wniesienia sprzeciwu wobec przetwarzania
        żądania przeniesienia danych do innego administratora


        Skontaktuj się z nami, jeśli chcesz skorzystać ze swoich praw.

        Jeśli uznasz, że Twoje dane są przetwarzane niezgodnie z prawem, możesz złożyć skargę do organu nadzorczego.
      </p>
      <h2>CIASTECZKA</h2>
      <p>Nasz Serwis, jak większość witryn internetowych, korzysta z tzw. plików cookies (ciasteczek). Pliki te:
        <br />
        <br />
        są zapisywane w pamięci Twojego urządzenia (komputera, telefonu, itd.)
        umożliwiają Ci, m.in., korzystanie ze wszystkich funkcji Serwisu
        nie powodują zmian w ustawieniach Twojego urządzenia
        <br />
        <br />
        Korzystając z odpowiednich opcji Twojej przeglądarki, w każdej chwili możesz:
        <ul>
          <li>usunąć pliki cookies</li>
          <li>blokować wykorzystanie plików cookies w przyszłości</li>
        </ul>
        <br />
        W tym Serwisie ciasteczka wykorzystywane są w celu:
        <ul>
          <li>zapamiętywania informacji o Twojej sesji</li>
          <li>statystycznym</li>
          <li>marketingowym</li>
          <li>udostępniania funkcji Serwisu</li>
        </ul>
        <br />
        Aby dowiedzieć się jak zarządzać plikami cookies, w tym jak wyłączyć ich obsługę w Twojej przeglądarce, możesz skorzystać z pliku pomocy Twojej przeglądarki. Z informacjami na ten temat możesz zapoznać się wciskając klawisz F1 w przeglądarce. Ponadto odpowiednie wskazówki znajdziesz na następujących podstronach, w zależności od przeglądarki, której używasz:
        <ul>
          <li>Firefox</li>
          <li>Chrome</li>
          <li>Safari</li>
          <li>Internet Explorer / Microsoft Edge</li>
        </ul>
        <br />
        Jeśli nie wyłączysz wykorzystywania plików cookies w ustawieniach przeglądarki, oznacza to, że wyrażasz zgodę na ich wykorzystanie.
        <br />
        <br />
        Więcej informacji o ciasteczkach znajdziesz w Wikipedii.
      </p>
      <h2>USŁUGI ZEWNĘTRZNE / ODBIORCY DANYCH</h2>
      <p>Korzystamy z usług podmiotów zewnętrznych, którym mogą być przekazywane Twoje dane. Poniżej znajduje się lista tych podmiotów:
        <ul>
          <li>podmiot realizujący dostawę towarów</li>
          <li>hurtownia</li>
          <li>drukarnia</li>
          <li>dostawca płatności</li>
          <li>biuro księgowe</li>
          <li>hostingodawca</li>
          <li>podmiot ułatwiający optymalizację Serwisu</li>
          <li>osoby współpracujące z nami na podstawie umów cywilnoprawnych, wspierające naszą bieżącą działalność</li>
          <li>dostawca oprogramowania ułatwiającego prowadzenie działalności (np. oprogramowanie księgowe)</li>
          <li>podmiot zapewniający usługi marketingowe</li>
          <li>dostawca oprogramowania potrzebnego do prowadzenia sklepu internetowego</li>
        </ul>
      </p>
      <h2>KONTAKT Z ADMINISTRATOREM</h2>
      <p>Chcesz skorzystać ze swoich uprawnień dotyczących danych osobowych?
      <br/>
      A może po prostu chcesz zapytać o coś związanego z naszą Polityką Prywatności?
      <br/>
      <br/>
      Napisz na adres e-mail:<br/>
      boguslaw.jurgiel@gmail.com
      </p>
    </div>
  )
}

export default PrivacyPolicy