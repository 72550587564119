import React from 'react'
import './Header.scss'
import LogoBlack from '../Assets/LOGOblack.avif'
import { HashLink } from 'react-router-hash-link'

const Header = () => {
    return (
        <div className='Header-container'>
           <a href='/'><img src={LogoBlack} alt='Rzeczoznawca majątkowy logo' /></a>
            <nav className='main-menu'>
                <ul>
                    <li><HashLink to='/#omnie' className='menu-link'>O mnie</HashLink></li>
                    <li><HashLink to='/#kontakt' className='menu-link'>Kontakt</HashLink></li>
                </ul>

            </nav>
        </div>
    )
}

export default Header