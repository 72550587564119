import React from 'react'
import './Oferta.scss'
import House from '../Assets/house.avif'
import Land from '../Assets/land.avif'
import Bakery from '../Assets/bakery.avif'

const Oferta = () => {
    return (
        <div className='oferta'>
            <h2>Oferuję operaty szacunkowe nieruchomości:</h2>
            <div className='oferta-box'>
                <img src={House} alt='' />
                <p>Zabudowanych: budynki mieszkalne, komercyjne,
                    <br />rozbudowy, nadbudowy, przebudowy, nakłady.
                </p>
            </div>
            <div className='oferta-box'>
                <img src={Land} alt='' />
                <p>Niezabudowanych: działki gruntów
                    <br />pod zabudowę i na cele rolne.
                </p>
            </div>
            <div className='oferta-box'>
                <img src={Bakery} alt='' />
                <p>Lokali mieszkalnych i niemieszkalnych: wraz
                    <br />z udziałami w częściach wspólnych i gruncie
                    <br />oraz nakłady na rozbudowę, nadbudowę,
                    <br />przebudowę.
                </p>
            </div>
        </div>
    )
}

export default Oferta