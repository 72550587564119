import React from 'react'
import './HomePage.scss'
import Hero from '../Components/Hero'
import Oferta from '../Components/Oferta'
import Wyksztalcenie from '../Components/Wyksztalcenie'
import Kontakt from '../Components/Kontakt'

const HomePage = () => {
  return (
    <div className='home-page'>
      <Hero />
      <Oferta />
      <Wyksztalcenie />
      <Kontakt />
    </div>
  )
}

export default HomePage