import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Header from './Components/Header';
import Footer from './Components/Footer';


function App() {
  return (
    <Router>
    <div className="App">
      <header className='App-header'>
        <Header />
      </header>
      <main className='App-main'>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/polityka-prywatnosci" element={<PrivacyPolicy />} />
        </Routes>
      </main>
      <footer className='App-footer'>
      <Footer />
      </footer>
    </div>
    </Router>
  );
}

export default App;
